import { useDebounceFn, useThrottleFn, useEventListener } from '@vueuse/core';
import { computed, inject, onUnmounted, provide, shallowRef, triggerRef, watch } from 'vue-demi';
import assertHasScope from '@/utils/other/assertHasScope';

const useRealTimeUpdatesSymbol = Symbol('realTimeUpdates');

/**
 * Basic normalization of the events coming over the WebSocket connection.
 */
function normalizeRawEvent(rawEvent) {
  const eventInfo = rawEvent.eventInfo || {};
  const extraInfo = eventInfo.extraInfo || {};
  let data = extraInfo.data || {};

  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch {
      console.warn(`Failed to parse twimEvent.eventInfo.extraInfo.data: ${data}`);
      data = {};
    }
  }

  return {
    ...rawEvent,
    eventInfo: {
      ...eventInfo,
      extraInfo: {
        ...extraInfo,
        data: {
          ...data,
        },
      },
    },
  };
}

function toId(value) {
  return Number(value) || null;
}

// `categoryType` must be normalized.
function toCategoryType(categoryType) {
  switch (categoryType) {
    case 'projects':
      return 'project';
    case 'files':
      return 'file';
    case 'notebooks':
      return 'notebook';
    case 'messages':
      return 'message';
    case 'links':
      return 'link';
    default:
      return categoryType;
  }
}

// `fileId` must be obtained from a link.
// See https://digitalcrew.teamwork.com/#/tasks/19198342
function toFileId(link) {
  if (typeof link !== 'string') {
    return null;
  }
  const match = /^files\/(\d+)/.exec(link);
  if (!match) {
    return null;
  }
  return Number(match[1]);
}

// We should really create a spec for the events,
// which would ideally define the normalized properties below.
// Then we could get rid of the `normalize` function.
function normalize({ eventInfo: event }) {
  const { extraInfo } = event;

  switch (event.itemType) {
    case 'task':
      return {
        type: 'task',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        taskListId: toId(extraInfo.taskListId),
        // populated when tasks are moved between lists
        previousTaskListId: toId(extraInfo.data.oldTaskListId),
        parentTaskId: toId(extraInfo.parentTaskId),
        taskId: toId(event.itemId),
        milestoneId: toId(extraInfo.data.milestoneId || extraInfo.data.newMilestoneId),
        previousMilestoneId: toId(extraInfo.data.oldMilestoneId),
        hasDependents: extraInfo.data.hasDependents,
      };
    case 'tasklist':
    case 'taskList':
      return {
        type: 'taskList',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        taskListId: toId(event.itemId),
      };
    case 'tasklistTasks':
    case 'taskListTasks':
      return {
        type: 'taskListTasks',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        taskListId: toId(event.itemId),
      };
    case 'projecttasks':
    case 'projectTasks':
      return {
        type: 'projectTasks',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
      };
    case 'file_comment':
    case 'task_comment': {
      const hasFile = extraInfo.objectType === 'file';
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'comment',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        fileId: toFileId(hasFile && (event.extraLink || event.itemLink)),
        fileVersionId: toId(hasFile && extraInfo.objectId),
        taskListId: toId(hasTask && extraInfo.taskListId),
        parentTaskId: toId(hasTask && extraInfo.parentTaskId),
        taskId: toId(hasTask && extraInfo.objectId),
        commentId: toId(event.itemId),
      };
    }
    case 'milestone':
      return {
        type: 'milestone',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        milestoneId: toId(event.itemId),
      };
    case 'event':
      return {
        type: 'event',
        action: event.actionType,
        detail: event.event,
        eventId: toId(event.itemId),
      };
    case 'time': {
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'time',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        // oldTaskListId, oldParentTaskId and oldTaskId are populated when a time log is detached from a task.
        taskListId: toId(hasTask && (extraInfo.taskListId || extraInfo.data.oldTaskListId)),
        parentTaskId: toId(hasTask && (extraInfo.parentTaskId || extraInfo.data.oldParentTaskId)),
        taskId: toId(hasTask && (extraInfo.objectId || extraInfo.data.oldTaskId)),
        timeId: toId(event.itemId),
      };
    }
    case 'usertimer': {
      const hasTask = extraInfo.taskId != null || extraInfo.data.taskId;
      return {
        type: 'timer',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        taskId: toId(hasTask && (extraInfo.taskId || extraInfo.data.taskId)),
        timeId: toId(event.itemId),
      };
    }
    case 'file':
      return {
        type: 'file',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        fileId: toFileId(event.itemLink),
        fileVersionId: toId(event.itemId),
      };
    case 'project':
      return {
        type: 'project',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        // When `action === 'rate-set' and the user rate was updated for a single user,
        // then `userId` is the ID of the user whose rate was updated.
        userId: toId(extraInfo.data.userId),
        categoryChanged: Boolean(extraInfo.data.categoryChanged),
      };
    case 'budget':
      return {
        type: 'budget',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        budgetId: toId(event.itemId),
      };
    case 'tasklistBudget':
      return {
        type: 'tasklistBudget',
        action: event.actionType,
        detail: event.event,
        tasklistId: toId(event.tasklistId),
        projectBudgetId: toId(event.projectBudgetId),
        tasklistBudgetId: toId(event.itemId),
      };
    case 'budgetExpense':
      return {
        type: 'budgetExpense',
        action: event.actionType,
        detail: event.event,
        projectBudgetId: toId(event.projectBudgetId),
        budgetExpenseId: toId(event.itemId),
      };
    case 'category':
      return {
        type: 'category',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        categoryId: toId(event.itemId),
        categoryType: toCategoryType(
          // "catgoryType" is needed for now as there's a typo in the server-sent event
          extraInfo.data.categoryType || extraInfo.data.catgoryType,
        ),
      };
    case 'person':
      return {
        type: 'person',
        action: event.actionType,
        detail: event.event,
        userId: toId(event.itemId),
      };
    case 'installation':
      return {
        type: 'installation',
        action: event.actionType,
        detail: event.event,
        installationId: toId(event.installationId),
      };
    case 'reminder': {
      const hasTask = extraInfo.objectType === 'task';
      return {
        type: 'reminder',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        taskListId: toId(hasTask && extraInfo.taskListId),
        taskId: toId(hasTask && extraInfo.objectId),
        parentTaskId: toId(hasTask && extraInfo.parentTaskId),
        reminderId: toId(event.itemId),
      };
    }
    case 'custom_type':
      return {
        type: 'customfield',
        action: event.actionType,
        detail: event.event,
        projectId: toId(event.projectId),
        customfieldId: toId(event.itemId),
      };
    case 'account':
      return {
        type: 'account',
        action: event.actionType,
        detail: event.event,
        accountId: toId(event.itemId),
      };
    case 'inboxItem':
      return {
        type: 'inboxItem',
        action: event.event,
        entity: event.entity,
        inboxItemId: toId(event.id),
      };
    case 'customreport':
      return {
        type: 'customReport',
        action: event.actionType,
        customReportId: toId(event.itemId),
        detail: event.event,
      };
    case 'report':
      return {
        type: 'report',
        action: event.actionType,
        reportId: toId(event.itemId),
        detail: event.event,
      };
    case 'jobRole':
      return {
        type: 'jobRole',
        action: event.actionType,
        detail: event.event,
        jobRoleId: toId(event.itemId),
      };
    case 'installationpref':
      return {
        type: 'accountPreference',
        action: event.actionType,
        accountId: toId(event.itemId),
      };
    case 'userpref':
      return {
        type: 'userPreference',
        action: event.actionType,
        userId: toId(event.itemId),
      };
    default:
      return {
        // Using `undefined`, so that no client code could depend on the non-normalized `event.type`
        // which is important because `event.type` is not always the same as `rawEvent.itemType`.
        type: undefined,
        action: event.actionType,
        detail: event.event,
      };
  }
}

/**
 * Provides a new real-time updates instance which can later be obtained using `useRealTimeUpdates`.
 */
export function provideRealTimeUpdates({ rtuDelay = shallowRef(5000) } = {}) {
  const handlingEventFromSocket = shallowRef(false);
  const sockets = shallowRef([]);
  const socketId = computed(() => {
    for (let i = 0; i < sockets.value.length; i += 1) {
      const socket = sockets.value[i].value;
      if (socket) {
        return `twa:${socket.id}`;
      }
    }
    return undefined;
  });
  let listeners = [];
  let emitting = 0;
  let pendingEvents = [];

  /**
   * Registers `listener` to be called on real-time updates events.
   * Must be called within a component.
   * Unregisters the listener automatically when the component unmounts.
   */
  function on(listener) {
    assertHasScope();

    // copy on write, if currently emitting
    if (emitting > 0) {
      listeners = listeners.slice();
    }
    listeners.push(listener);

    onUnmounted(() => {
      // copy on write, if currently emitting
      if (emitting > 0) {
        listeners = listeners.slice();
      }
      listeners.splice(listeners.lastIndexOf(listener), 1);
    });
  }

  /**
   * Emits the specified, normalized real-time updates event.
   *
   * @param event A normalized real-time updates event.
   * @param rawEvent An optional raw real-time updates events received from the server.
   *   `rawEvent` is only intended for use by Notifications. View this thread for context:
   *   https://github.com/Teamwork/frontend/pull/587#issuecomment-967238253
   */
  function emit(event, rawEvent) {
    try {
      emitting += 1;
      if (rawEvent) {
        handlingEventFromSocket.value = true;
      }
      // thanks to "copy on write" above, fixedListeners will not change while iterating
      const fixedListeners = listeners;
      for (let i = 0; i < fixedListeners.length; i += 1) {
        try {
          fixedListeners[i](event, rawEvent);
        } catch (error) {
          console.error('useRealTimeUpdates: Error in an event listener', error);
        }
      }
    } finally {
      if (rawEvent) {
        handlingEventFromSocket.value = false;
      }
      emitting -= 1;
    }
  }

  function emitPendingEvents() {
    if (pendingEvents.length > 0 && document.visibilityState === 'visible') {
      const events = pendingEvents;
      pendingEvents = [];
      events.forEach(({ event, rawEvent }) => emit(event, rawEvent));
    }
  }

  // We use throttling to ensure that we deliver the events to loaders at most once every `rtuDelay` milliseconds.
  // We avoid unnecessary delays by processing the events at the start and end of the `rtuDelay` millisecond interval.
  //
  // We use debouncing to batch the events arriving within 1 second because they often result from the same action.
  // We avoid excessive delays by limiting the wait time to `rtuDelay` milliseconds.
  const emitPendingEventsLater = useDebounceFn(useThrottleFn(emitPendingEvents, rtuDelay, true, true), 1000, {
    maxWait: rtuDelay,
  });

  /**
   * Handles data change events coming from the server over WebSocket.
   */
  function onEventNotice(rawEvent) {
    // eslint-disable-next-line no-param-reassign
    rawEvent = normalizeRawEvent(rawEvent);

    // In most cases server-side data modification can be completed fast. It works as follows:
    //
    // 1. Client sends a request (includes a Socked-ID header).
    // 2. Server receives the request.
    // 3. Server updates data.
    // 4. Server sends an event (isAsync === false; includes the value of the Socket-ID header).
    // 5. Server sends a response.
    // 6. Client receives the response.
    // 7. Client emits a local event based on the response.
    // 8. Client handles the local event.
    // 9. Client ignores the server event.
    //
    // In rare cases server-side data modification is known to need much time. It works as follows:
    //
    // 1. Client sends a request (includes a Socked-ID header).
    // 2. Server receives the request.
    // 3. Server spawns a thread or schedules an action to update data asynchronously.
    // 4. Server sends a response.
    // 5. Client receives the response.
    // 6. Server updates the data.
    // 7. Server sends an event (isAsync === true; includes the value of the Socket-ID header).
    // 8. Client handles the server event.
    //
    // We use local events for API requests sent from the same browser tab because we have the information
    // necessary to emit such events, and they provide greater reliability and reduced latency
    // which are critical for user experience, as this guarantees that users will see their own changes with
    // minimum delay.

    // Determines if this event was emitted as a result of an API request sent from the same browser tab.
    const isForLocalAction = rawEvent.eventInfo.socketId != null && rawEvent.eventInfo.socketId === socketId.value;

    // Determines if this event was emitted asynchronously, some time after the API request completed.
    const isAsync = rawEvent.eventInfo.extraInfo.data.threadedLoopback || false;

    // Ignore the server events for which we emit equivalent local events.
    if (isForLocalAction && !isAsync) {
      return;
    }

    // The format of the server events is inconsistent and hard to work with, so we normalize the raw events.
    const event = normalize(rawEvent);

    pendingEvents.push({ event, rawEvent });
    emitPendingEventsLater();
  }

  /**
   * Forwards events from the specified TWIM socket.
   */
  function emitFromSocket(socket) {
    assertHasScope();

    watch(socket, () => socket.value && socket.value.on('eventNotice', onEventNotice), { immediate: true });
    onUnmounted(() => socket.value && socket.value.off('eventNotice', onEventNotice));

    sockets.value.push(socket);
    triggerRef(sockets);
    onUnmounted(() => {
      sockets.value.splice(sockets.value.lastIndexOf(socket), 1);
      triggerRef(sockets);
    });
  }

  useEventListener(document, 'visibilitychange', emitPendingEvents, false);

  provide(useRealTimeUpdatesSymbol, {
    emit,
    emitFromSocket,
    on,
    socketId,
    handlingEventFromSocket,
  });
}

/**
 * Returns the real-time updates instance provided by `provideRealTimeUpdates`.
 * If `listener` is specified, it is registered to listen for real-time updates events.
 */
export function useRealTimeUpdates(listener) {
  const realTimeUpdates = inject(useRealTimeUpdatesSymbol);
  if (listener) {
    realTimeUpdates.on(listener);
  }

  return realTimeUpdates;
}
